import React, { useState, useEffect } from 'react';
// import './mcFormStyles.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe"
// import { useGHStContext } from '../../../utils/ContextProvider';
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"
import NtheosLogo from '../../images/icon.png'

const InputField = styled.input`
height: 4.5rem;
width: 90%;
left: 5%;
margin: 2.5rem;
border-radius: 1rem; 
background: rgba(255,255,255,0.25);
border: 1px solid white;

::placeholder {
    color: white;
    margin-left: 1rem;
}

 &:focus {
     outline: none;
     box-shadow: 1px 1px 7.5px ${blue};

 }
`

const PrimaryCTAButton = styled.button`
max-width: 10rem;
`

const Form = styled.form`
height: 70vh;
width: 50rem;
margin: 0 auto;
position: fixed;
top: 50%;
right: 50%;
transform: translate(50%,-50%);
background: rgba(0,0,0,0.33);
border-radius: 1rem;
box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
flex-direction: column;
display: flex;
align-items: center;
backdrop-filter: blur(12.5px);

${MEDIA.TABLET`
// font-size: 60%;
width: 95%;

  `};

`

const SubmitButton = styled.input`
width: 12.5rem; 
height: 5rem;
border-radius: 1rem;
border: 1px solid ${orange};
box-shadow: 1px 1px 10px ${orange};
background: rgba(255,255,255,0.1);
color: white;
transition: .4s ease all;

&:hover{
    box-shadow: 1px 1px 12.5px ${blue};
    cursor: pointer;
    color: ${blue};
    
}
`

const Header = styled.h3`
color: white;
font-size: 2rem;
margin-top: 2.5rem;
font-weight: 600;
width: 66%;
text-align: center;

img {
    margin: 1.5rem;
    height: 3rem;
}
`


const CustomForm = ({
    status,
    message,
    onValidated,
}) => {


    const { modalOpen, setModalOpen } = useState(true);



    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');



    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
            firstName &&
            lastName &&
            email.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
            });
    }

    useEffect(() => {
        if (status === "success") clearFields();
        if (modalOpen && status === "success") {
            clearFields()
        }
    }, [status, modalOpen])

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    }


    return (
        <Form
            className="mc__form"
            onSubmit={(e) => handleSubmit(e)}
        >
            <Header className="mc__title">
                <img src={NtheosLogo} alt='ntheos logo' />

                <p>
                    {status === "success" ?
                        "Welcome to the club! Juicy, perhaps edgy and sometimes perplexing emails on the way to your inbox now!" :
                        "Join our email list for future updates."}
                </p>
            </Header>

            {status === "sending" && (
                <div
                    className="mc__alert mc__alert--sending"
                >sending...</div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            {status !== "success" ? (
                <div className="mc__field-container">
                    <InputField
                        label="First Name"
                        onChange={e => setFirstName(e.target.value)}
                        type="text"
                        value={firstName}
                        placeholder="Jane"
                        isRequired
                    />

                    <InputField
                        label="Last Name"
                        onChange={e => setLastName(e.target.value)}
                        type="text"
                        value={lastName}
                        placeholder="Doe"
                        isRequired
                    />

                    <InputField
                        label="Email"
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        value={email}
                        placeholder="your@email.com"
                        isRequired
                    />

                </div>
            ) : null}

            {/*Close button appears if form was successfully sent*/}
            {
                status === 'success' ? <PrimaryCTAButton
                    handleClick={() => setModalOpen(false)}
                    label="close"
                    size="big"
                    customClass="g__justify-self-center"
                /> :
                    <SubmitButton
                        label="subscribe"
                        type="submit"
                        formValues={[email, firstName, lastName]}
                    />

            }
        </Form>
    );
};


const MailchimpForm = ({ closeModal }) => {
    const url = `https://ntheos.us5.list-manage.com/subscribe/post?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}`;


    const timelyCloseModal = () => {
        setTimeout(() => {
            console.log("modal byebye");
            closeModal();
        },
            5500);
    }

    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => {
                            subscribe(formData)
                            timelyCloseModal()
                        }
                        }
                    />
                )}
            />
        </div>

    )
}

export default MailchimpForm;