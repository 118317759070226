
import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, lightgrey } from "constants/theme"


export const Container = styled.div`
width: 100%;
height: 100%;
position: absolute;
top: -35rem;
max-width: 1205px;

${MEDIA.L_TABLET`


`};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
min-width: 0px;
width: 100%;

  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};

`


export const Gif = styled.img`
${({ gif }) => (
        `background-image: url(${gif});`
    )}
max-width: 1205px;
    // background-size: cover;
    object-fit: cover;

    min-height: 85%;
    height: auto;
    width: 100%;


${MEDIA.L_TABLET`


`};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
  object-fit: fill;

min-height: 150%;
left: -5%;
width: 110%;
position: absolute;
  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};
`