import React from 'react';
import PropTypes from 'prop-types';
import { HomeStatementStyle, WordLoop } from './statement.css';
import { useRef, useEffect, useState } from 'react'
import { blue, orange, grey, } from "../../constants/theme"


const HomeStatement = ({ children, as = '', size }) => {

  function useInterval(callback, delay) {

    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }


  let [count, setCount] = useState(0);

  useInterval(() => {
    count === 0 ? setCount(1) : count === 1 ? setCount(2) : count === 2 ? setCount(3) :
      count === 3 ? setCount(4) : count === 4 ? setCount(5) : count === 5 ? setCount(6) :
        count === 6 ? setCount(7) :
          setCount(0)
  }, 2000);


  return (
    <HomeStatementStyle >
      Welcome to the
      <br />
      Ntheos magazine.
      <br />
      Where we discuss
      <br />

      <WordLoop>
        {
          count === 0 ? "Innovation" : count === 1 ? "Blockchain" :
            count === 2 ? "Crypto" : count === 3 ? "NFTs" :
              count === 4 ? "Tokenomics" :
                count === 5 ? "Defi" :
                  count === 6 ? "Web 3.0" :
                    count === 7 ? "Metaverse" :

                      <strong>the future</strong>
        }
        <br />
      </WordLoop>
      <br />
      {/* < span style={{ color: orange }} >keep seeking.</span > */}
    </HomeStatementStyle >
  );
};



HomeStatement.propTypes = {
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
};

export default HomeStatement;