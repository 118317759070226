import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby'
import { Container, Gif } from './heroGif.css';


const HeroGif = ({ gif, children, type, as = '', }) => {
    console.log("gif source: ", gif)

    return (
        <Container>
            <Gif
                // src='https://media.giphy.com/media/HUFVmIktUUpNC5VkbL/source.mov'
                gif={gif} />
        </Container >
    );
};

HeroGif.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default HeroGif;
