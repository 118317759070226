import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import HomeStatement from '../components/Statement/homeStatement';
import { graphql, Link } from 'gatsby';
import Section from '../components/Section/section'
import { MainSectionContainer } from '../components/Section/section.css'
import FeatureStatement from "../components/Statement/featureStatement"
import CardList from '../components/article/articleList/articleList'
import HeroGif from '../components/hero/heroGif/heroGif';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import MailchimpForm from '../components/mailchimp';


const Index = ({ data }) => {
  const heroGif = data.coverGif.edges[0].node.frontmatter.coverGif
  const heroTitle = data.coverGif.edges[0].node.frontmatter.title
  const heroSlug = data.coverGif.nodes[0].fields.slug
  console.log('cover gif: ', data.coverGif.edges[0].node.frontmatter.coverGif)
  const url = typeof window !== 'undefined' ? window.location.href : '';
  console.log("index data", data)
  const posts = data.allMarkdownRemark.edges
  console.log("url", url)
  const postsPerPage = 9
  let featured;
  const postsWithoutFeatured = posts.filter(({ node }) => {
    if (node.frontmatter.featured) {
      featured = node
    }
    return !node.frontmatter.featured
  })

  const [loaded, setLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // add script for coinmarekt cap marquee 
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = 'https://files.coinmarketcap.com/static/widget/coinMarquee.js';
    scriptTag.addEventListener('load', () => setLoaded(true));
    document.body.appendChild(scriptTag);

  }, [])

  // timer here 

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalOpen(true);
    }, 7500);
    return () => clearTimeout(timer);
  }, []);

  // loaded
  useEffect(() => {
    if (!loaded) return;


  }, [loaded])

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  return (
    <Layout>

      <Popup open={modalOpen} modal>

        <NewsletterModal>
          <span tabIndex={0}
            role='button' onKeyPress={() => { console.log('') }} onClick={handleCloseModal}>x</span>
          <MailchimpForm />


        </NewsletterModal>
      </Popup>

      <HomeStatement />
      {/* <Section section='tags' header bg='white' heading='' size='small' /> */}

      <Section type="theater" section='featured' heading='feature project' size='medium'>
        <Link to={`${heroSlug}`}>


          <FeatureStatement title={heroTitle} color='white' />



          {/* <div
            style={{
              position: 'relative',
              background: 'red'
            }}
            id="coinmarketcap-widget-marquee"
            coins="1,1027,5426,11171,7431,3513,4172,3794,1934,6636,4558,6187,7083,8075,1966,6783,11213,13237,5805,3635,8916,4195,6210,2011,2130,5632,1697,5604,12220"
            currency="USD"
            // theme="light"
            // transparent="true"
            show-symbol-logo="false"></div> */}

          <MainSectionContainer type="theater">
            <HeroGif
              allow="autoplay; fullscreen; "
              gif={heroGif}
            />



          </MainSectionContainer>
        </Link>

      </Section>
      <Section section="latest" bg='white' size='auto'>

        <CardList posts={postsWithoutFeatured} />
      </Section>

    </Layout>
  )
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};



export default Index;


const NewsletterModal = styled.div`
width: 50rem;
height: 50rem;
background: white;

`


export const query = graphql`
 query HomeQuery {
  coverGif: allMarkdownRemark(
    filter: {frontmatter: {featured: {eq: true}}}
    sort: {order: DESC, fields: frontmatter___date}
    limit: 1
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          type
          date
          coverGif
        }
      }
    }
    nodes {
      fields {
        slug
      }
    }
  }


  allMarkdownRemark(
  sort: {order: DESC, fields: frontmatter___date}
  filter: {frontmatter: {type: {eq: "vid"}}}
  limit: 10
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "ddd MMMM Do, YYYY")
          description
          featured
          coverGif
          cover {
            childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              }
            }
          }
          last_modified
          tags
          title
          type
        }
      }
    }
  }
}

`;
